import React, { useEffect, useState } from "react";
import Breadcrumb from "./breadcrumb";
import { api_url, image_url } from "../config";
import "./../css/contact.css";
import axios from "axios";

const initialFormState = {
  name: "",
  email: "",
  subject: "",
  msg: "",
};

const Contact = (props) => {
  let imgURL = image_url;

  let apiURL = api_url;

  const [pageBreadcrumb, setBreadcrumb] = useState();
  const [bannerImage, setBannerImage] = useState();
  const [secondSectionData, setSecondData] = useState();
  const [sectionThirdData, setThirdData] = useState();
  const [msgCheck, setMsgCheck] = useState(false);

  const [formData, setFormData] = useState(initialFormState);
  const [errors, setErrors] = useState({});

  const handleSubmit = (e) => {
    e.preventDefault();

    if (validateForm()) {
      axios
        .post(apiURL + "enquiry", formData)
        .then((response) => {
          // Handle success response
          setFormData(initialFormState);
          setErrors({});
          setMsgCheck(true);
          setTimeout(() => {
            setMsgCheck(false);
          }, 3000);
        })
        .catch((error) => {
          // Handle error
          console.error(error);
        });
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));

    // Clear error message when the field value changes
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "", // Clear the error message for the field
    }));
  };

  const validateForm = () => {
    let isValid = true;
    const newErrors = {};

    // Perform validation checks here
    if (formData.name.trim() === "") {
      newErrors.name = "Please enter a name";
      isValid = false;
    }

    if (formData.email.trim() === "") {
      newErrors.email = "Please enter an email";
      isValid = false;
    }

    if (formData.subject.trim() === "") {
      newErrors.subject = "Please select a subject";
      isValid = false;
    }

    // Add more validation checks for other fields

    setErrors(newErrors);
    return isValid;
  };

  useEffect(() => {
    setBreadcrumb(props?.data[0][0].page_name);
    props?.data?.map((res) => {
      if (res[0]?.section_name == "banner") {
        setBannerImage(imgURL + "banner_image/" + res[0]?.image);
      } else if (res[0]?.section_name == "second_section") {
        setSecondData(res[0]?.text_content);
      } else if (res[0]?.section_name == "third_section") {
        setThirdData(res[0]?.text_content);
      }
    });
  }, []);

  const breadcrumbObject = {
    img: bannerImage,
    breadcumbs: pageBreadcrumb,
  };

  return (
    <>
      <Breadcrumb data={breadcrumbObject} />
      <section className="contact_section">
        <div className="contact_content">
          <p
            className="para_family"
            dangerouslySetInnerHTML={{ __html: secondSectionData }}
          ></p>
        </div>
        <div className="form_row">
          <div className="contact_form">
            <form onSubmit={handleSubmit}>
              <div className="parentSection">
                <label htmlFor="name">Name</label>
                <input
                  type="text"
                  name="name"
                  id="name"
                  value={formData?.name}
                  onChange={handleInputChange}
                  className="form-control"
                  required
                />
                {errors.name && <span className="error">{errors.name}</span>}
              </div>
              <div className="parentSection">
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  name="email"
                  value={formData?.email}
                  onChange={handleInputChange}
                  pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                  id="email"
                  className="form-control"
                  required
                />
                {errors.email && <span className="error">{errors.email}</span>}
              </div>
              <div className="parentSection">
                <label htmlFor="subject">Subject</label>
                <select
                  className="form-control"
                  name="subject"
                  id="dropdown"
                  required
                  value={formData?.subject}
                  onChange={handleInputChange}
                >
                  <option value="" selected disabled></option>
                  <option value="I need help in booking a ticket">
                    I need help in booking a ticket
                  </option>
                  <option value="I’ve got feedback on Third Space">
                    I’ve got feedback on Third Space
                  </option>
                  <option value="I’d like to hire a space">
                    I’d like to hire a space
                  </option>
                  <option value="I’d like to run a workshop or event">
                    I’d like to run a workshop or event
                  </option>
                  <option value="I’ve lost something ">
                    I’ve lost something{" "}
                  </option>
                  <option value="I’d like to share my feedback on TS Website">
                    I’d like to share my feedback on TS Website
                  </option>
                  <option value="I have something else to share">
                    I have something else to share
                  </option>
                  <option value="I need to know the discount structure for booking for a group">
                    I need to know the discount structure for booking for a
                    group
                  </option>
                  <option value="I want to know about the activities at TS">
                    I want to know about the activities at TS
                  </option>
                </select>
                {errors.subject && (
                  <span className="error">{errors.subject}</span>
                )}
              </div>
              <div>
                <label htmlFor="message">Message</label>
                <textarea
                  id="message"
                  name="msg"
                  value={formData?.msg}
                  onChange={handleInputChange}
                  className="form-control"
                ></textarea>
              </div>
              <div className="text-right mt-30 send_btn">
                <button type="submit" className="submit_btn">
                  Submit
                </button>
              </div>
              {msgCheck ? (
                <p
                  style={{
                    fontFamily: "kohinoorregular",
                    fontSize: "18px",
                    color: "#5cb85c",
                  }}
                >
                  Form submitted successfully!
                </p>
              ) : (
                ""
              )}
            </form>
          </div>
          <div className="iframe_row">
            {/* <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3627.108049246194!2d73.720719!3d24.6199606!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3967e52e86399e29%3A0x49c18b2e981e6cd3!2sThird%20Space!5e0!3m2!1sen!2sin!4v1686816847061!5m2!1sen!2sin" style={{ border: "0", margin: "0", padding: "0" }} loading="lazy"></iframe> */}
            {/* <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3627.1079941272624!2d73.7207344!3d24.6199625!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3967e5c01b799c95%3A0x863fc229940cc565!2sJP9C%2BX7Q%2C%20Arihant%20Nagar%2C%20Gandhi%20Nagar%2C%20Udaipur%2C%20Rajasthan%20313001!5e0!3m2!1sen!2sin!4v1686131503282!5m2!1sen!2sin" style={{ border: "0", margin: "0", padding: "0" }} loading="lazy"></iframe> */}
            {/* <iframe src="https://www.google.com/maps/place/JP9C%2BX7Q,+Arihant+Nagar,+Gandhi+Nagar,+Udaipur,+Rajasthan+313001/@24.6199625,73.7207344,17z/data=!4m5!3m4!1s0x3967e5c01b799c95:0x863fc229940cc565!8m2!3d24.6199625!4d73.7207344" style={{ border: "0", margin: "0", padding: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}
            <p
              className="para_family"
              dangerouslySetInnerHTML={{ __html: sectionThirdData }}
            >
              {/* {sectionThirdData} */}
              {/* JP9C+X7Q, Shyam Nagar, Pulla Bhuwana, Arihant Nagar,
                            Pahada, Udaipur, Rajasthan 313001
                            thirdspace@thirdspace.org
                            +91 7357658777 */}
            </p>
          </div>
        </div>
      </section>
    </>
  );
};

export default Contact;
