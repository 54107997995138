import React, { useEffect, useState } from "react";
import { api_url,image_url } from "../config";
import { useParams } from "react-router-dom";
import axios from "axios";
import Home from "./home";
import Library from "./library";
import Contact from "./contact";
import Spaces from "./spaces";
import Loader from "../repeated/loader";
import Visiter from "./visiter";
import Opening from "./opening";
import Accessibility from "./accessibility";
import Frontier from "./Frontier";
import { Helmet } from "react-helmet";
import Carousels from "./third-space-dynamic-map/Carousels";


const Combined = () => {
  window.scroll({
    top: 0,
    left: 0,
    behavior: "smooth",
  });

  const apiURL = api_url;
  const { slug1, slug2, slug3 } = useParams();

  const [templateID, setTemplateID] = useState("");
  const [propsData, setPropsData] = useState([]);
  const [loaderState, setLoaderState] = useState(true);
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [newData, setNewData] = useState();
  const [URL, setUrl] = useState("");
  const [metaFields, setMetaFields] = useState({});

  useEffect(() => {
    setLoaderState(true);
    const getData = async () => {
      try {
        let url = apiURL;
        if (slug1) url += `${slug1}`;
        if (slug2) url += `/${slug2}`;
        if (slug3) url += `/${slug3}`;
        else if (!slug1 && !slug2 && !slug3) url += `home`;
        setUrl(url);
        const response = await axios.get(url);
        setTemplateID(response?.data?.data[0][0].template_id);
        setPropsData(response?.data?.data);
        console.log(response)
        setMetaFields(response?.data?.data[0][0]);
        setNewData(response?.data);
        setBreadcrumbs(response?.data?.breadcrumbmenu);
        setLoaderState(false);
      } catch (error) {
        setLoaderState(false);
        console.error("Error occurred while fetching Page:", error);
      }
    };

    getData();
  }, [slug1, slug2, slug3, URL]);

  return (
    <>
      {metaFields && (
        <Helmet>
          <title>{metaFields?.meta_title}</title>
          <meta name="description" content={metaFields?.meta_description} />
          <meta name="keywords" content={metaFields?.keywords} />
          <meta property="og:title" content={metaFields?.og_title} />
          <meta
            property="og:description"
            content={metaFields?.og_description}
          />
          <meta property="og:image" content={`${image_url}og_image/${metaFields?.og_image}`} />
          <meta property="og:url" content={`${image_url}og_image/${metaFields?.og_image}`} />
          <meta name="twitter:title" content={metaFields?.meta_title} />
          <meta
            name="twitter:description"
            content={metaFields?.meta_description}
          />
          <meta name="twitter:image" content={`${image_url}og_image/${metaFields?.og_image}`} />
          <meta name="twitter:card" content="summary_large_image" />
        </Helmet>
      )}
      {loaderState ? (
        <div>
          <Loader /> <div style={{ height: "100vh" }}></div>
        </div>
      ) : (
        <div>
          {templateID === 1 && <Home data={propsData} />}
          {templateID === 2 && <Library data={propsData} />}
          {templateID === 3 && <Contact data={propsData} />}
          {templateID === 4 && <Spaces data={propsData} />}
          {templateID == 5 && (
            <Frontier
              data={propsData}
              breadcrumbs={breadcrumbs}
              newData={newData}
            />
          )}
          {templateID === 6 && <Visiter data={propsData} />}
          {templateID === 7 && <Opening data={propsData} />}
          {templateID === 8 && <Accessibility data={propsData} />}
          {templateID === 9 && <Carousels data={propsData} />}
        </div>
      )}
    </>
  );
};

export default Combined;
