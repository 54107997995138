import React, { Component, useCallback, useEffect, useState } from 'react';
import logoImage from './../assets/3rd_space_logo final.png';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import { api_url } from './../config.js';
import debounce from 'lodash.debounce';
import { Dialog } from 'primereact/dialog';
import Loader from './loader';

const Header = () => {

    let apiURL = api_url;

    const currentURL = useParams();

    const [menudata, setData] = useState([]);
    const [menuIndex, setMenuIndex] = useState();
    const [subMenusForMobile, setSubMenusForMobile] = useState(false);
    const [subSubMenusForMobile, setSubSubMenusForMobile] = useState(false);
    const [subMenuCheck, setSubMenuCheck] = useState(false);
    const [subMenuIndex, setSubMenuIndex] = useState();
    const [subSubMenuCheck, setsubSubMenuCheck] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [openMenus, setBooleanForMenus] = new useState(false);
    const [searchList, setSearchList] = new useState();
    const [displayBasic, setDisplayBasic] = new useState(false);


    const getMenuData = async (url) => {
        const res = await axios.get(url + 'menu');
        setData(res?.data?.menu?.menus);
    }

    const handleSearch = useCallback(
        debounce((value) => {
            searchQuery(value);
        }, 300),
        []
    );

    useEffect(() => {
        getMenuData(apiURL);
    }, [])

    useEffect(() => {
        handleSearch(searchTerm);
    }, [handleSearch, searchTerm]);

    useEffect(() => {
        setSearchTerm();
    }, [currentURL?.slug])

    const handleChange = (event) => {
        const { value } = event.target;
        setSearchTerm(value);
    };

    const openMobileMenus = () => {
        setBooleanForMenus(!openMenus);
    }

    const hideMenus = () => {
        setBooleanForMenus(false);
    }

    const opneSubMenuForMobile = (index, string) => {
        if (string == 'submenu') {
            setMenuIndex(index);
            setSubMenuCheck(!subMenuCheck)
            setSubMenusForMobile(!subMenusForMobile);
            if (!subMenuCheck) {
                setsubSubMenuCheck(false);
                setSubSubMenusForMobile(false);
            }
        }
        else if (string == 'subsubmenu') {
            setSubMenuIndex(index);
            setsubSubMenuCheck(!subSubMenuCheck);
            setSubSubMenusForMobile(!subSubMenusForMobile);
        }
    }

    const onListClick = () => {
        setSearchList();
        setSearchTerm('');
        setDisplayBasic(false);
    }

    const searchQuery = (value) => {
        if (value) {
            var searchObject = {
                name: value
            }
            const response = axios.post(apiURL + 'globalSearch', searchObject);
            response.then((res) => {
                const uniqueArray = res?.data?.data.filter(
                    (item, index, array) => index === array.findIndex(obj => (
                        obj.page_name === item.page_name
                    ))
                );
                setSearchList(uniqueArray);
            }).catch((error) => {
                setSearchList();
            });
        }
    }

    const openSearchModal = () => {
        setDisplayBasic(true);
    }

    const onHide = () => {
        setDisplayBasic(false);
    }

    useEffect(() => {
        document.addEventListener('click', onWindowClick);
    }, [])

    const onWindowClick = (event) => {
        if (event.target.id != 'searchbar') {
            setSearchTerm('');
            setSearchList([]);
        }
    }

    return (
        <>
            <Dialog header="Search Globally" visible={displayBasic} style={{ width: '90vw' }} onHide={onHide}>
                <div className='searchLI'>
                    <div className='search_input_section'>
                        <input type='text' placeholder='Search here...' id='searchbar' value={searchTerm} onChange={handleChange} />
                        <i className="fa fa-search for_search_bar"></i>
                    </div>
                    {searchList?.length > 0 ?
                        <div className='searchList'>
                            <ul>
                                {searchList?.map((list) =>
                                    <li>
                                        <Link to={list?.slug} onClick={onListClick}>
                                            {list?.page_name}
                                        </Link>
                                    </li>
                                )}
                            </ul>
                        </div>
                        : ''}
                </div>
            </Dialog>

            {menudata?.length == 0 ? <Loader /> :
                <section className="header_section" id="header">
                    <div className="head_logo">
                        <div className="web_logo">
                            <Link to="/">
                                <img src={logoImage} alt="" width="100%" height="100%" />
                            </Link>
                        </div>
                    </div>
                    <div className="head_nav_links">
                        <div className="nav_links_div">
                            <div className="language_dropdown">
                                {menudata?.length >= 5 ?
                                    <div className="nav_link1">
                                        <i className="fa fa-search"></i>
                                    </div>
                                    : ''}
                                <div className="nav_link para_family">
                                    <select name="language" className="form-control" style={{ cursor: "pointer" }}>
                                        <option value="en"><span>English</span></option>
                                        <option value="hi" disabled>Hindi</option>
                                    </select>
                                </div>
                                <div className="nav_link para_family d-flex align-items-center"
                                    style={{ color: "#6E6E6E", fontFamily: "kohinoorregular" }}>
                                    <span>
                                        <Link to={'contact'}>
                                            Contact
                                        </Link>
                                    </span>
                                </div>
                                <div className="login_btn">
                                    <button>
                                        <Link to={'https://booking.third-space.in/'}>
                                            Buy ticket
                                        </Link>
                                    </button>
                                </div>
                            </div>
                            <ul className="unOrderNavLinks">
                                {menudata.map((res, key) =>
                                    <li className="nav_link para_family navigation_menus" key={res.id}>
                                        <span>
                                            {res?.title_eng != 'Visit us' ?
                                                <>
                                                    {res?.title_eng == 'Home' ?
                                                        <Link to='/'>
                                                            {res?.title_eng}
                                                        </Link>
                                                        :
                                                        <Link to={res?.slug_eng}>
                                                            {res?.title_eng}
                                                        </Link>
                                                    }
                                                </>
                                                : (res?.title_eng)}
                                        </span>
                                        {res?.subpage?.length > 0 ? <span><i className="fa fa-angle-down" style={{ fontSize: "13px", paddingLeft: "5px" }}></i></span> : ''}
                                        {/* {res?.title_eng == 'Spaces' ? 
                                    
                                    : ''} */}
                                        {res?.subpage?.length > 0 ?
                                            <div className={res.title_eng == "Spaces" ? "space" : "row unOrderSubNavLinks"}>
                                                {/* <div className="row unOrderSubNavLinks"> */}
                                                <div className={res.title_eng == "Spaces" ? "unOrderSubNavSpacesLinks" : "p-0"}>
                                                    {res?.subpage?.map((subMenu, keys) =>
                                                        <div className="subpage">
                                                            <div className='for_space'>
                                                                <span className='para_family navigation_sub_menus'>
                                                                    {res.title_eng == "Spaces" ?
                                                                        <span className='spaces_class' to={subMenu?.slug_eng}>
                                                                            {subMenu?.title_eng}
                                                                        </span> :
                                                                        <Link to={subMenu?.slug_eng}>
                                                                            {subMenu?.title_eng}
                                                                        </Link>
                                                                    }
                                                                </span>
                                                            </div>
                                                            {/* // :
                                                            // <div className='for_not'>
                                                            //     <span className='nav_link para_family navigation_sub_menus'>
                                                            //         <Link to={subMenu?.slug_eng}>
                                                            //             {subMenu?.title_eng} {subMenu?.subpage?.length > 0 ? <span><i className="fa fa-angle-down" style={{ fontSize: "13px", paddingLeft: "5px" }}></i></span> : ''}
                                                            //         </Link>
                                                            //     </span>
                                                            // </div> */}
                                                            {/* } */}
                                                            {
                                                                subMenu?.subpage?.length > 0 ?
                                                                    subMenu?.subpage?.map((subMenuPage) =>
                                                                        // <div className="d-flex justify-content-between child-menu">
                                                                        //     <div className="d-flex justify-content-between" style={{ padding: "10px 0" }}>
                                                                        <ul className='child-menu' style={{ padding: "0 15px" }}>
                                                                            <li>
                                                                                {subMenuPage?.is_disabled == 1

                                                                                    ?
                                                                                    <span style={{ color: "#acabab" }}>{subMenuPage?.title_eng}</span>
                                                                                    :
                                                                                    <Link to={subMenuPage?.slug_eng}>
                                                                                        {subMenuPage?.title_eng}
                                                                                    </Link>
                                                                                }
                                                                            </li>
                                                                        </ul>
                                                                        //     </div>
                                                                        // </div>
                                                                    )
                                                                    :
                                                                    ''
                                                            }
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                            : ''
                                            // <ul className="row unOrderSubNavLinks1" >
                                            //     {res?.subpage?.map((subMenu) =>
                                            //         <li className="sub_nav_link para_family col-lg-4">
                                            //             <div className="d-flex justify-content-between">
                                            //                 <span>
                                            //                     <Link to={subMenu?.slug_eng}>
                                            //                         {subMenu?.title_eng}
                                            //                     </Link>
                                            //                 </span>
                                            //             </div>
                                            //         </li>
                                            //     )}
                                            // </ul>
                                        }
                                        {/* {res?.subpage?.length > 0 && res.title_eng != 'Spaces' ?
                                        <ul className="row unOrderSubNavLinks" >
                                            {res?.subpage?.map((subMenu) =>
                                                <li className="sub_nav_link para_family col-lg-4">
                                                    <div className="d-flex justify-content-between">
                                                        <span>
                                                            <Link to={subMenu?.slug_eng}>
                                                                {subMenu?.title_eng}
                                                            </Link>
                                                        </span>
                                                    </div>
                                                </li>
                                            )}
                                        </ul> : ''} */}
                                    </li>
                                )}
                                {menudata?.length < 5 ?
                                    <li className='searchLI'>
                                        <div className='search_input_section'>
                                            <input type='text' placeholder='Search here...' id='searchbar' value={searchTerm} onChange={handleChange} />
                                            <i className="fa fa-search for_search_bar"></i>
                                        </div>
                                        {searchList?.length > 0 ?
                                            <div className='searchList'>
                                                <ul>
                                                    {searchList?.map((list) =>
                                                        <Link to={list?.slug} onClick={onListClick}>
                                                            <li>
                                                                {list?.page_name}
                                                            </li>
                                                        </Link>
                                                    )}
                                                </ul>
                                            </div>
                                            : ''}
                                    </li>
                                    : ''}
                                {/* <li className="nav_link para_family navigation_menus">
                                <span>
                                    home
                                </span>
                            </li>
                            <li className="nav_link para_family navigation_menus">
                                <span>
                                    What’s on today
                                </span>
                                <span><i className="fa fa-angle-down" style={{ fontSize: "13px", paddingLeft: "5px" }}></i></span>
                                <ul className="unOrderSubNavLinks">
                                    <li className="sub_nav_link para_family">
                                        <div className="d-flex justify-content-between">
                                            <span>
                                                Menu 1
                                            </span>
                                        </div>
                                    </li>
                                    <li className="sub_nav_link para_family">
                                        <div className="d-flex justify-content-between">
                                            <span>
                                                Menu 2
                                            </span>
                                        </div>
                                    </li>
                                </ul>
                            </li>
                            <li className="nav_link para_family navigation_menus">
                                <span>
                                    what’s on
                                </span>
                                <span><i className="fa fa-angle-down" style={{ fontSize: "13px", paddingLeft: "5px" }}></i></span>
                                <ul className="unOrderSubNavLinks">
                                    <li className="sub_nav_link para_family">
                                        <div className="d-flex justify-content-between">
                                            <span>
                                                Menu 1
                                            </span>
                                        </div>
                                    </li>
                                    <li className="sub_nav_link para_family">
                                        <div className="d-flex justify-content-between">
                                            <span>
                                                Menu 2
                                            </span>
                                        </div>
                                    </li>
                                </ul>
                            </li>
                            <li className="nav_link para_family navigation_menus">
                                <span>
                                    About the building
                                </span>
                                <span><i className="fa fa-angle-down" style={{ fontSize: "13px", paddingLeft: "5px" }}></i></span>
                                <ul className="unOrderSubNavLinks">
                                    <li className="sub_nav_link para_family">
                                        <div className="d-flex justify-content-between">
                                            <span>
                                                Menu 1
                                            </span>
                                        </div>
                                    </li>
                                    <li className="sub_nav_link para_family">
                                        <div className="d-flex justify-content-between">
                                            <span>
                                                Menu 2
                                            </span>
                                        </div>
                                    </li>
                                </ul>
                            </li> */}
                            </ul>
                        </div>
                    </div>
                    <div className="resp_head_nav_links">
                        <div className="nav_icon d-flex align-items-center">
                            <div className="nav_link d-flex para_family">
                                <select name="language" className="form-control" style={{ cursor: "pointer" }}>
                                    <option value="en"><span>English</span></option>
                                    <option value="hi" disabled>Hindi</option>
                                </select>
                            </div>
                            <div className="nav_link1">
                                <i className="fa fa-search" onClick={openSearchModal}></i>
                            </div>
                            <button className="navbar-toggler pr-0" type="button" data-bs-toggle="collapse"
                                data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                                aria-expanded="false" aria-label="Toggle navigation">
                                {openMenus ? '' :
                                    <span style={{ display: "flex", alignItems: "center" }}>
                                        <i style={{ fontSize: "24px" }} className="fa fa-bars light-color" onClick={openMobileMenus} id="open-icon"></i>
                                    </span>
                                }
                                {openMenus ?
                                    <span style={{ display: "flex", alignItems: "center" }}>
                                        <i style={{ fontSize: "24px" }} className="fa light-color" onClick={openMobileMenus} id="close-icon">&#xf00d;</i>
                                    </span>
                                    : ''}
                            </button>
                        </div>
                    </div>
                    {openMenus ?
                        <div className="open-menu">
                            {menudata?.length > 0 ?
                                <ul className="responsive-menu">
                                    {menudata.map((res, index) =>
                                        <li className="nav_link para_family">
                                            <span>
                                                {res?.title_eng == 'Home' ?
                                                    <Link to='/' onClick={hideMenus}>
                                                        {res?.title_eng}
                                                    </Link>
                                                    :
                                                    <Link to={res?.slug_eng} onClick={hideMenus}>
                                                        {res?.title_eng}
                                                    </Link>
                                                }
                                            </span>
                                            {res?.subpage?.length > 0 ?
                                                <span>
                                                    {subMenusForMobile && menuIndex == index ?
                                                        <i className="fa fa-angle-up" onClick={() => opneSubMenuForMobile(index, 'submenu')} style={{ marginLeft: "10px" }}></i>
                                                        :
                                                        <i className="fa fa-angle-down" onClick={() => opneSubMenuForMobile(index, 'submenu')} style={{ marginLeft: "10px" }}></i>
                                                    }
                                                </span>
                                                : ''}

                                            {subMenuCheck && menuIndex == index ?
                                                <ul className="unOrderSubNavLinks" style={{ listStyle: "none", paddingLeft: "15px", maxHeight: "150px", overflow: "auto" }}>
                                                    {res?.subpage?.map((response, subIndex) =>
                                                        <li className="sub_nav_link para_family">

                                                            <span>
                                                                {res.title_eng == 'Spaces'
                                                                    ?
                                                                    response?.title_eng
                                                                    :
                                                                    <Link to={response?.slug_eng} onClick={hideMenus}>
                                                                        {response?.title_eng}
                                                                    </Link>
                                                                }
                                                            </span>

                                                            {response?.subpage?.length > 0 ?
                                                                <span>
                                                                    {subSubMenusForMobile && subMenuIndex == subIndex ?
                                                                        <i className="fa fa-angle-up" onClick={() => opneSubMenuForMobile(subIndex, 'subsubmenu')} style={{ marginLeft: "10px" }}></i>
                                                                        :
                                                                        <i className="fa fa-angle-down" onClick={() => opneSubMenuForMobile(subIndex, 'subsubmenu')} style={{ marginLeft: "10px" }}></i>
                                                                    }
                                                                </span>
                                                                : ''}

                                                            {subSubMenuCheck && subMenuIndex == subIndex ?
                                                                <ul className="subsublink" style={{ listStyle: "none", paddingLeft: "15px" }}>
                                                                    {response?.subpage?.map((resp) =>
                                                                        <li>
                                                                            <span>
                                                                                {resp?.is_disabled == 1 ?
                                                                                    <span style={{ color: "#acabab" }}>{resp?.title_eng}</span> :
                                                                                    <Link to={resp?.slug_eng} onClick={hideMenus}>
                                                                                        {resp?.title_eng}
                                                                                    </Link>
                                                                                }
                                                                            </span>
                                                                        </li>
                                                                    )}
                                                                </ul>
                                                                : ''}

                                                        </li>
                                                    )}
                                                </ul>
                                                : ''}

                                        </li>
                                    )}
                                    <li className="nav_link para_family">
                                        <Link to='/contact' onClick={hideMenus}>
                                            Contact
                                        </Link>
                                    </li>
                                </ul>
                                : ''}
                        </div>
                        : ''}
                </section >
            }
        </>
    );
}

export default Header;