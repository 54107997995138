import React, { useEffect, useState } from "react";
import "./../css/library.css";
import { Link, useParams } from "react-router-dom";
import { image_url } from "./../config.js";
import DynamicBreadcrumb from "./DynamicBreadcrumb.js";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import { MdOutlineKeyboardArrowUp } from "react-icons/md";

const Frontier = (props) => {
  const path=useParams()
  let imgURL = image_url;
  const [openItemIndex, setOpenItemIndex] = useState('');
  const toggleAnswer = (index) => {
    setOpenItemIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  const pageBreadcrumb = props?.data;
  let bannerImage = "";
  let firstSectionData = [];
  let sectionCardHeading = "";
  let sectionFourthData = [];
  let sectionFifthData = [];

  props?.data?.map((res) => {
    if (res[0]?.section_name == "banner") {
      bannerImage = imgURL + "banner_image/" + res[0]?.image;
    } else if (res[0]?.section_name == "second_section") {
      firstSectionData = res[0];
    } else if (res[0]?.section_name == "third_section") {
      sectionCardHeading = res[0].heading;
    } else if (res[0]?.section_name == "fourth_section") {
      sectionFourthData = res;
    } else if (res[0]?.section_name == "fifth_section") {
      sectionFifthData = res;
    }
  });

  const breadcrumbObject = {
    img: bannerImage,
    breadcumbs: pageBreadcrumb,
  };
  const subBreadcrumbs =
    props?.breadcrumbs?.breadcrumbmenus[0]?.subpage_breadcrumbs;


  return (
    <div style={{marginTop:'24vh'}}>
      <DynamicBreadcrumb
        data={breadcrumbObject}
        subBreadcrumbs={subBreadcrumbs}
        parentChildBreadcrumb={props?.newData}
      />
      <section className="library_section_desktop main_content_details">
        <div className="row m-0 library_row">
          <div className="col-md-6 col-12 lib-content-col">
            <div className="details_section">
              <div className="heading_section">
                <h1 className="main_heading_desktop">
                  {firstSectionData?.heading}
                </h1>
                <h1 className="main_heading_mobile">
                  {firstSectionData?.content_heading}
                </h1>
                {path=="frontiers" && firstSectionData?.sub_heading ? (
                  <h2 className="main_library_subHeading">
                    {firstSectionData?.sub_heading}
                  </h2>
                ) : (
                  ""
                )}
                {path=="frontiers" && (firstSectionData?.from_time && firstSectionData?.to_time) ? (
                  <p className="firstSection_timing">
                    {firstSectionData?.from_time} - {firstSectionData?.to_time}
                  </p>
                ) : (
                  ""
                )}
              </div>
              <div className="both_section">
                <div className="content_section">
                  <p
                    className="light-color"
                    dangerouslySetInnerHTML={{
                      __html: firstSectionData?.text_content,
                    }}
                  ></p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-12 lib-image-col-desktop">
            <img
              className="responsive-image"
              src={imgURL + "first_section_image/" + firstSectionData?.image}
              alt=""
            />
          </div>
          <div className="col-md-6 col-12 lib-image-col-mobile">
            <div className="row m-0">
              <div className="col-7" style={{ paddingLeft: "0" }}>
                <img
                  src={
                    imgURL + "first_section_image/" + firstSectionData?.image
                  }
                  alt=""
                  width="100%"
                />
              </div>
              <div
                className="col-5 d-flex align-items-center"
                style={{ paddingLeft: "0" }}
              >
                <div>
                  <h1 className="mobile_heading">
                    {firstSectionData?.heading}
                  </h1>
                  {firstSectionData?.sub_heading ? (
                    <p className="mobile_sub_heading">
                      {firstSectionData?.sub_heading}
                    </p>
                  ) : (
                    ""
                  )}
                  {firstSectionData?.from_time && firstSectionData?.to_time ? (
                    <p className="mobile_content">
                      {/* {firstSectionData?.from_time} -{" "}
                      {firstSectionData?.to_time} */}
                    </p>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <section className="search_card_section">
                <div className="row m-0">
                    <div className="col-md-6 col-6 search_cat_section">
                        <div className="combined_content">
                            <h2 className="search_cat_section_heading">{secondSectionData?.section2_page_left_heading}</h2>
                            {secondSectionData?.section2_text1?.map((res, index) =>
                                <a href={secondSectionData?.section2_link1[index]} target='_blank'>
                                    <p className="search_cat_section_content">
                                        {res}
                                    </p>
                                </a>
                            )}
                        </div>
                        {currentURL.slug == 'library' ?
                            <button className="search_cat_btn for_library_page">Search catalogue</button>
                            :
                            <button className="search_cat_btn for_another_page">Search catalogue</button>
                        }
                    </div>
                    <div className="col-md-6 col-6 book_section">
                        <div className="combined_content">
                            <h2 className="search_cat_section_heading">{secondSectionData?.section2_page_right_heading}</h2>
                            {secondSectionData?.section2_text2?.map((res, index) =>
                                <a href={secondSectionData?.section2_link2[index]} target='_blank'>
                                    <p className="search_cat_section_content">
                                        {res}
                                    </p>
                                </a>
                            )}
                        </div>
                        <button className="search_cat_btn">Book the spot</button>
                    </div>
                </div>
            </section> */}

      {sectionFourthData?.length > 0 && (
        <section className="space_listing_section" style={{paddingTop:'0px', paddingBottom:'90px'}}>
          <h2 className="main_heading" style={{paddingLeft:'15px', paddingBottom:'15px'}}>{sectionCardHeading}</h2>
          <div className="row m-0 nav_link_cards">
            {sectionFourthData?.length > 0 &&
              sectionFourthData?.map((res, index) => (
                <div className="project_card">
                  <Link
                    style={{ textDecoration: "none", cursor: "pointer" }}
                    to={`/${props?.breadcrumbs?.breadcrumbmenus[0]?.slug}/${props?.newData?.data[0][0]?.slug}/${res?.url}`}
                  >
                    <div className="card_box">
                      <div className="card_image">
                        <img
                          src={imgURL + "section_fourth_image/" + res?.image}
                        />
                      </div>
                      <div className="card_content">
                        <h2 className="card_heading m-0 heading_family">
                          {res?.sub_heading}
                        </h2>
                        {index == 0 ? (
                          <div
                            className="design_section"
                            style={{ background: "#ffca05" }}
                          ></div>
                        ) : (
                          ""
                        )}
                        {index == 1 ? (
                          <div
                            className="design_section"
                            style={{ background: "#f26f21" }}
                          ></div>
                        ) : (
                          ""
                        )}
                        {index == 2 ? (
                          <div
                            className="design_section"
                            style={{ background: "#e21f26" }}
                          ></div>
                        ) : (
                          ""
                        )}
                        {index == 3 ? (
                          <div
                            className="design_section"
                            style={{ background: "#ffca05" }}
                          ></div>
                        ) : (
                          ""
                        )}
                        {index == 4 ? (
                          <div
                            className="design_section"
                            style={{ background: "#f26f21" }}
                          ></div>
                        ) : (
                          ""
                        )}
                        {index == 5 ? (
                          <div
                            className="design_section"
                            style={{ background: "#e21f26" }}
                          ></div>
                        ) : (
                          ""
                        )}
                        {index == 6 ? (
                          <div
                            className="design_section"
                            style={{ background: "#ffca05" }}
                          ></div>
                        ) : (
                          ""
                        )}
                        {index == 7 ? (
                          <div
                            className="design_section"
                            style={{ background: "#f26f21" }}
                          ></div>
                        ) : (
                          ""
                        )}
                        {index == 8 ? (
                          <div
                            className="design_section"
                            style={{ background: "#e21f26" }}
                          ></div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </Link>
                </div>
              ))}
          </div>
        </section>
      )}

      {sectionFifthData.length > 0 && (
        <section className="library_section_desktop main_content_details" style={{paddingTop:'0px'}}>
          <div className="detailList">
            {sectionFifthData?.map((detail, index) => (
              <div
                className="detailCard"
                key={index}
              >
                <div className="detailCardUpper">
                  <h2 className="mobile_heading">{detail?.heading}</h2>
                  {openItemIndex === index ? (
                    <MdOutlineKeyboardArrowUp
                      className="arrowDown"
                      id="toggleAnswerUp"
                      onClick={() => toggleAnswer(index)}
                      style={{boxShadow:'none'}}
                    />
                  ) : (
                    <MdOutlineKeyboardArrowDown
                      id="toggleAnswerDown"
                      className="arrowDown"
                      onClick={() => toggleAnswer(index)}
                      style={{boxShadow:'none'}}
                    />
                  )}
                </div>
                {openItemIndex === index && (
                  <div className="answer">
                    <div
                      className="answerLeft"
                      style={{ width: detail?.image ? "50%" : "100%" }}
                      dangerouslySetInnerHTML={{
                        __html: detail?.text_content,
                      }}
                    >
                      
                    </div>
                    {detail?.image && (
                      <div className="answerRight">
                        <img
                          src={
                            imgURL +
                            "section_fifth_image/" +
                            detail?.image
                          }
                          alt="fifth section Img"
                          width="100%"
                        />
                      </div>
                    )}
                  </div>
                )}
                <hr
                  className="line"
                  style={{
                    display: openItemIndex === index ? "none" : "block",
                  }}
                />
              </div>
            ))}
          </div>
        </section>
      )}
    </div>
  );
};

export default Frontier;
