import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";

const DynamicBreadcrumb = (props) => {
  const { slug1, slug2, slug3 } = useParams();
  const [subBreadcrumbs, setSubBreadcrumbs] = useState({});
 
  useEffect(() => {
    const storedSubBreadcrumbs = localStorage.getItem("subBreadcrumbs");
    if (storedSubBreadcrumbs) {
      if (slug2?.includes(JSON.parse(storedSubBreadcrumbs).slug)) {
        setSubBreadcrumbs(JSON.parse(storedSubBreadcrumbs));
      } else if (slug3) {
        setSubBreadcrumbs(JSON.parse(storedSubBreadcrumbs));
      }
    }
  }, [slug2, slug1, slug3]);

  const breadcumbsHandler = (e, item) => {
    setSubBreadcrumbs(item);
    localStorage.setItem("subBreadcrumbs", JSON.stringify(item));
  };

  return (
    <>
      <section className="overview_section">
        <div className="row static_row m-0">
          <div className="col-12 map_col p-0">
            <img src={props?.data?.img} alt="breadcrumb" />
          </div>
        </div>
      </section>
      <section className="breadcrumb_section">
        <div className="d-flex justify-content-between">
          <div className="breadcrumb_links heading_family">
            {"Home"}
            <div className="breadcrumb_dots1"></div>
            <div className="breadcrumb_dots2"></div>
            <div className="breadcrumb_dots3"></div>
            <div>
              {props?.parentChildBreadcrumb?.parentPage && (
                <div className="breadcrumb_links heading_family">
                  {props?.parentChildBreadcrumb?.parentPage?.page_name}{" "}
                  <div className="breadcrumb_dots1"></div>
                  <div className="breadcrumb_dots2"></div>
                  <div className="breadcrumb_dots3"></div>
                </div>
              )}
            </div>
            <div>
              {props?.parentChildBreadcrumb?.childPage && (
                <div className="breadcrumb_links heading_family">
                  {props?.parentChildBreadcrumb?.childPage?.page_name}{" "}
                  <div className="breadcrumb_dots1"></div>
                  <div className="breadcrumb_dots2"></div>
                  <div className="breadcrumb_dots3"></div>
                </div>
              )}
            </div>
            <div>
              {props?.data?.breadcumbs &&
                props?.data?.breadcumbs[0][0]?.page_name}
            </div>
          </div>
          <div className="breadcrumb_links heading_family">
            {props?.subBreadcrumbs
              ?.map((item) => (
                <Link
                  key={item?.slug}
                  to={`/${props?.parentChildBreadcrumb?.breadcrumbmenu?.breadcrumbmenus[0].slug}/${item?.slug}`}
                >
                  <div
                    style={{
                      color: slug2?.includes(item?.slug) ? "black" : "#6E6E6E",
                    }}
                    onClick={(e) => breadcumbsHandler(e, item)}
                    className="px-2 d-none d-lg-inline"
                  >
                    {item?.title}
                  </div>
                  <div
                    style={{
                      color: slug2?.includes(item?.slug) ? "black" : "#6E6E6E",
                    }}
                    onClick={(e) => breadcumbsHandler(e, item)}
                    className="px-2 d-lg-none d-inline"
                  >
                    {item?.title &&
                      item.title
                        .split(" ")
                        .map((word) => word.charAt(0).toUpperCase())
                        .join("")}
                  </div>
                </Link>
              ))
              .map((link, index, array) => (
                <div key={index} className="d-flex ">
                  {link}
                  {index < array.length - 1 && " | "}
                </div>
              ))}
          </div>
        </div>
      </section>
      {/* <section className="breadcrumb_links heading_family d-flex justify-content-end pr-2 d-md-none d-inline">
        {props?.subBreadcrumbs
          ?.map((item) => (
            <Link
              key={item?.slug}
              to={`/${props?.parentChildBreadcrumb?.breadcrumbmenu?.breadcrumbmenus[0].slug}/${item?.slug}`}
            >
              <div
                style={{
                  color: slug2?.includes(item?.slug) ? "black" : "#6E6E6E",
                }}
                onClick={(e) => breadcumbsHandler(e, item)}
                className="px-2 "
              >
                {item?.title &&
                  item.title
                    .split(" ")
                    .map((word) => word.charAt(0).toUpperCase())
                    .join("")}
              </div>
            </Link>
          ))
          .map((link, index, array) => (
            <div key={index} className="d-flex ">
              {link}
              {index < array.length - 1 && " | "}
            </div>
          ))}
      </section> */}
      {subBreadcrumbs?.subpage_breadcrumbs?.length > 0 &&<div style={{ background: "#f4f4f4", height: "10px" }}></div>}
      {subBreadcrumbs?.subpage_breadcrumbs?.length > 0 && (
        <section className="breadcrumb_section d-flex justify-content-end d-none d-lg-flex">
          <div className="breadcrumb_links heading_family">
            {subBreadcrumbs?.subpage_breadcrumbs
              ?.map((item) => (
                <div
                  style={{
                    color: slug3?.includes(item?.slug) ? "black" : "#6E6E6E",
                  }}
                  className="px-2"
                >
                  <Link
                    key={item?.slug}
                    to={`/${props?.parentChildBreadcrumb?.breadcrumbmenu?.breadcrumbmenus[0].slug}/${subBreadcrumbs?.slug}/${item?.slug}`}
                  >
                    {item?.title}
                  </Link>
                </div>
              ))
              .map((link, index, array) => (
                <div key={index} className="d-flex">
                  {link}
                  {index < array.length - 1 && " | "}
                </div>
              ))}
          </div>
        </section>
      )}
    </>
  );
};

export default DynamicBreadcrumb;
