import React, { Component, useEffect, useRef, useState } from 'react';
import './../css/library.css'
// import bannerImage from './../assets/banner.png';
import libraryImage from './../assets/library.png';
import card1Image from './../assets/card_img.png';
import card2Image from './../assets/card-2.png';
import card3Image from './../assets/card-3.png';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Breadcrumb from './breadcrumb';
import { api_url, image_url } from './../config.js';

const Library = (props) => {

    let imgURL = image_url;

    const currentURL = useParams();

    const [bannerImage, setBannerImage] = useState();
    const [firstSectionData, setFirstSectionData] = useState();
    const [secondSectionData, setSecondData] = useState();
    const [sectionThirdData, setThirdData] = useState();
    const [sectionFourthData, setFourthData] = useState();
    const [sectionCardHeading, setCardHeading] = useState();
    const [pageBreadcrumb, setBreadcrumb] = useState();

    useEffect(() => {
        const getData = async () => {
            setBreadcrumb(props?.data[0][0].page_name);
            props?.data?.forEach((res) => {
                if (res[0]?.section_name == 'banner') {
                    setBannerImage(imgURL + 'banner_image/' + res[0]?.image);
                } else if (res[0]?.section_name == 'first_section') {
                    setFirstSectionData(res[0]);
                } else if (res[0]?.section_name == 'second_section') {
                    setSecondData(JSON.parse(res[0]?.json_data));
                } else if (res[0]?.section_name == 'third_section') {
                    setThirdData(res);
                    setCardHeading(res[0].heading);
                } else if (res[0]?.section_name == 'fourth_section') {
                    setFourthData(res[0]);
                }
            });
        };
        getData();
    }, []);

    const breadcrumbObject = {
        img: bannerImage,
        breadcumbs: pageBreadcrumb
    }

    return (
        <>
            <Breadcrumb data={breadcrumbObject} />
            <section className="library_section_desktop main_content_details">
                <div className="row m-0 library_row">
                    <div className="col-md-6 col-12 lib-content-col">
                        <div className="details_section">
                            <div className="heading_section">
                                <h1 className="main_heading_desktop">
                                    {firstSectionData?.heading}
                                </h1>
                                <h1 className="main_heading_mobile">
                                    {firstSectionData?.content_heading}
                                </h1>
                                {firstSectionData?.sub_heading ?
                                    <h2 className='main_library_subHeading'>
                                        {firstSectionData?.sub_heading}
                                    </h2>
                                    : ''}
                                {(firstSectionData?.from_time && firstSectionData?.to_time) ?
                                    <p className='firstSection_timing'>
                                        {firstSectionData?.from_time} - {firstSectionData?.to_time}
                                    </p>
                                    : ''}
                            </div>
                            <div className="both_section">
                                <div className="content_section">
                                    <p className="light-color" dangerouslySetInnerHTML={{ __html: firstSectionData?.text_content }}>
                                        {/* “… a mind needs books as a sword needs a whetstone, if it is to
                                        keep its edge…”George RR Martin Feed your mind with amazing
                                        stories; inspiring, scary, funny...whatever you want. Come discover
                                        the wonders of our world. Our library is a recharge station for your
                                        mind, a moment of calm and peace. (But you don’t have to be
                                        silent here!) Find other book lovers, discover new writers, and try
                                        your own hand at writing. And if you’re not in the mood for stories,
                                        try out our board game collection to sharpen your mind! From
                                        strategy to dexterity, we’re sure to have something you will enjoy. */}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-12 lib-image-col-desktop">
                        <img src={imgURL + 'first_section_image/' + firstSectionData?.image} alt="" width="100%" />
                    </div>
                    <div className="col-md-6 col-12 lib-image-col-mobile">
                        <div className="row m-0">
                            <div className="col-7" style={{ paddingLeft: "0" }}>
                                <img src={imgURL + 'first_section_image/' + firstSectionData?.image} alt="" width="100%" />
                            </div>
                            <div className="col-5 d-flex align-items-center" style={{ paddingLeft: "0" }}>
                                <div>
                                    <h1 className="mobile_heading">{firstSectionData?.heading}</h1>
                                    {firstSectionData?.sub_heading ?
                                        <p className='mobile_sub_heading'>
                                            {firstSectionData?.sub_heading}
                                        </p>
                                        : ''}
                                    {(firstSectionData?.from_time && firstSectionData?.to_time) ?
                                        <p className='mobile_content'>
                                            {firstSectionData?.from_time} - {firstSectionData?.to_time}
                                        </p>
                                        : ''}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="search_card_section">
                <div className="row m-0">
                    <div className="col-md-6 col-6 search_cat_section">
                        <div className="combined_content">
                            <h2 className="search_cat_section_heading">{secondSectionData?.section2_page_left_heading}</h2>
                            {secondSectionData?.section2_text1?.map((res, index) =>
                                <a href={secondSectionData?.section2_link1[index]} target='_blank'>
                                    <p className="search_cat_section_content">
                                        {res}
                                    </p>
                                </a>
                            )}
                        </div>
                        {currentURL.slug == 'library' ?
                            <button className="search_cat_btn for_library_page">Search catalogue</button>
                            :
                            <button className="search_cat_btn for_another_page">Search catalogue</button>
                        }
                    </div>
                    <div className="col-md-6 col-6 book_section">
                        <div className="combined_content">
                            <h2 className="search_cat_section_heading">{secondSectionData?.section2_page_right_heading}</h2>
                            {secondSectionData?.section2_text2?.map((res, index) =>
                                <a href={secondSectionData?.section2_link2[index]} target='_blank'>
                                    <p className="search_cat_section_content">
                                        {res}
                                    </p>
                                </a>
                            )}
                        </div>
                        <button className="search_cat_btn">Book the spot</button>
                    </div>
                </div>
            </section>
            <section className="redirection_card_section">
                <h2 className="main_heading">{sectionCardHeading}</h2>
                <div className="row m-0 nav_link_cards">
                    {sectionThirdData?.map((res, index) =>
                        <div className="project_card">
                            <a style={{ textDecoration: "none", cursor: "pointer" }} href={res?.url}>
                                <div className="card_box">
                                    <div className="card_image">
                                        <img src={imgURL + 'section_third_image/' + res?.image} />
                                    </div>
                                    <div className="card_content">
                                        <h2 className="card_heading m-0 heading_family">{res?.sub_heading}</h2>
                                        {index == 0 ?
                                            <div className="design_section" style={{ background: "#ffca05" }}></div>
                                            : ''}
                                        {index == 1 ?
                                            <div className="design_section" style={{ background: "#f26f21" }}></div>
                                            : ''}
                                        {index == 2 ?
                                            <div className="design_section" style={{ background: "#e21f26" }}></div>
                                            : ''}
                                    </div>
                                </div>
                            </a>
                        </div>
                    )}
                </div>
            </section>

            {(sectionFourthData?.heading || sectionFourthData?.text_content) ? 
            <section className="infoSection">
                {sectionFourthData?.heading ? 
                <h2 className="main_heading_mobile">
                    {sectionFourthData?.heading}
                </h2>
                :''}
                {sectionFourthData?.text_content ? 
                <div className="info_list">
                    <p className="list_heading" dangerouslySetInnerHTML={{ __html: sectionFourthData?.text_content }}>
                        {/* {sectionFourthData?.text_content} */}
                        {/* Today- Storytelling */}
                    </p>
                    {/* <ul>
                        <li>
                            Board games evenings
                        </li>
                        <li>
                            Book swaps
                        </li>
                    </ul> */}
                </div>
                :''}
                {/* <h2 className="main_heading_mobile" style={{ fontSize: "15px" }}>
                    Tomorrow- Lorem ipsum
                </h2>
                <a className="anchor_link" href="">Linktoevent</a> */}
            </section>
            :''}
        </>
    );
}

export default Library;