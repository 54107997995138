import React from "react"
import './../css/loader.css'

const Loader = () => {
    return (
        <>
            <div className="snippet" data-title=".dot-typing">
                <div className="stage">
                    <div className="dot-typing"></div>
                </div>
            </div>
        </>
    );
}

export default Loader

